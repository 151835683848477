import __Base from '@components/Base';
import uniqid from '@utils/lodash/uniqid';
import unique from '@utils/lodash/unique';
import clsx from 'clsx';
import _, { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import Creatable from 'react-select/creatable';
import { axios } from '@services/http/client';
class DanimSelect extends __Base {
  constructor(props) {
    super(props);
    this.id = uniqid();
    this.state = {}; // see getDerivedStateFromProps
    this.loadDebounce = _.debounce(this.load, this.props.debounceWait);
  }
  static getDerivedStateFromProps = (nextProps, prevState) => {
    return {
      // mirroring, see https://stackoverflow.com/a/50080417/6503789
      ...((typeof _.get(prevState, '_options') === 'undefined' || !_.isEqual(nextProps.options, prevState._options)) && {
        _options: nextProps.options
      }),
      // derivation
      ...((typeof _.get(prevState, '_options') === 'undefined' || !_.isEqual(nextProps.options, prevState._options)) && !nextProps.loadOptions // if options have changed in a static mode context
      ? {
        loading: false,
        inputValue: '',
        options: nextProps.options
      } : null)
    };
  };
  componentDidMount = () => {
    const {
      loadOptions,
      mountLoad
    } = this.props;
    if (loadOptions && mountLoad) this.loadDebounce();
  };
  componentDidUpdate = () => {
    const {
      loadOptions
    } = this.props;

    // if loadOptions params have changed, reload
    if (loadOptions && loadOptions.params) {
      const params = loadOptions.params(this.state.inputValue || '');
      if (!isEqual(params, this.lastLoadOptionsParams)) {
        this.lastLoadOptionsParams = params;
        this.loadDebounce();
      }
    }
  };
  handleChange = opt => {
    const {
      onChange
    } = this.props;
    let value;
    if (_.isArray(opt)) {
      if (opt.length) {
        if (_.find(opt, el => el.value === 'all')) {
          value = _.map(this.options(false), el => _.get(el, 'value', null));
        } else {
          value = _.map(opt, el => _.get(el, 'value', null));
        }
      } else {
        value = _.map(opt, el => _.get(el, 'value', null));
      }
    } else {
      value = _.get(opt, 'value', null);
    }
    if (_.isArray(value)) {
      value = unique(value);
    }
    onChange(value);
  };
  handleInputChange = value => {
    const {
      onInputChange
    } = this.props;
    this.updateState({
      inputValue: value
    });
    if (onInputChange) onInputChange(value);
  };
  handleKeyDown = event => {
    const {
      inputValue
    } = this.state;
    const {
      creatable
    } = this.props;
    let {
      options
    } = this.state;
    if (!inputValue) return;
    if (!creatable) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const added = {
          label: inputValue,
          value: inputValue
        };
        options = _.isArray(options) ? options : [options];
        let alreadyThere = false;
        _.each(options, option => {
          if (option.value === added.value) alreadyThere = true;
        });
        this.updateState({
          inputValue: '',
          options: alreadyThere ? options : [...options, added]
        }, () => {
          const currentValue = this.value();
          this.handleChange(this.props.isMulti ? currentValue ? [...(_.isArray(currentValue) ? currentValue : [currentValue]), added] : [added] : added);
        });
        if (event) event.preventDefault();
    }
  };
  inputValue = () => this.state.inputValue;
  load = () => {
    const {
      loadOptions,
      value
    } = this.props;
    this.updateState({
      loading: true
    }, async () => {
      const underWhitelabel = !window.areWeUnderDanimHost();
      const options = {
        method: loadOptions.method || 'post',
        url: loadOptions.url || gen('read'),
        data: loadOptions.params ? loadOptions.params(this.state.inputValue || '') : {},
        underWhitelabel
      };
      await axios(options, {
        then: data => {
          let options = loadOptions.options(data, value);
          this.updateState({
            options,
            loading: false
          });
        },
        catch: () => this.updateState({
          loading: false
        })
      });
    });
  };
  options = allableProps => {
    const {
      allable
    } = this.props;
    const {
      options
    } = this.state;
    if (!options) return null;
    if (!allableProps && !allable) return options;
    if (options && options.length > 0 && options[0].value === 'all') return options;
    return [{
      value: 'all',
      label: tr('front.select.placeholder.all')
    }, ...options];
  };
  value = () => {
    const {
      value,
      getOptionValue
    } = this.props;
    if (value === null) return null;
    const options = this.options(false);
    const match = (option, value) => {
      return _.isObject(option.value) && (_.isObject(value) && JSON.stringify(option.value) === JSON.stringify(value) || option.value.id === value || getOptionValue && getOptionValue(option) === value) || option.value === value;
    };
    return !_.isArray(value) ? _.find(options, option => match(option, value)) : _.filter(options, option => !_.isEmpty(_.filter(value, item => match(option, item))));
  };
  render = () => {
    const {
      creatable,
      customAttributes,
      error,
      helper,
      label,
      loadOptions,
      isClearable,
      isMulti,
      menuPlacement,
      name,
      placeholder,
      styles,
      warning,
      isDisabled
    } = this.props;
    const Component = creatable ? Creatable : Select; // avoid conflict with herein Select
    const options = this.options(this.props.allable) || [];
    const value = this.value();
    const props = this.props;
    // props.getOptionValue && delete props.getOptionValue; // handled in this.value

    const color = 'hsl(var(--color-1-h), var(--color-1-s), calc(var(--color-1-dark) * var(--color-1-l) + var(--color-1-light) * 40%))';
    const feedback = error || warning || helper || null;
    return <div ref={obj => this.root = obj} data-attr='root' {..._.get(customAttributes, 'root', {})} className={clsx('select', warning && '/warning', error && '/error', _.get(customAttributes, 'root.className'), isDisabled ? 'disabled' : '')} data-test-id={`${name}Select`}>
                {(label || '') !== '' && <Label for={this.id}>{label}</Label>}
                <Component {...props} data-attr='select' {..._.get(customAttributes, 'select', {})} classNamePrefix='select' className={clsx('-select', _.get(customAttributes, 'select.className'))} styles={{
        ...styles,
        control: (provided, state) => ({
          ...provided,
          borderColor: state.isFocused ? color : '#ced4da',
          boxShadow: state.isFocused ? '0 0 0 2px rgba(70, 190, 138, 0.25)' : 'initial',
          '&:hover': {
            borderColor: state.isFocused ? color : '#ced4da',
            boxShadow: state.isFocused ? '0 0 0 2px rgba(70, 190, 138, 0.25)' : 'initial'
          },
          '&:focus': {
            borderColor: state.isFocused ? color : '#ced4da',
            boxShadow: state.isFocused ? '0 0 0 2px rgba(70, 190, 138, 0.25)' : 'initial'
          },
          ...(_.get(styles, 'control') ? styles.control(provided, state) : {})
        }),
        option: (provided, state) => {
          const {
            data,
            isSelected,
            isDisabled
          } = state;
          return {
            ...provided,
            ...(data.value === 'all' && {
              fontStyle: 'italic',
              fontWeight: 'bold',
              borderStyle: 'solid',
              borderColor: 'lightgrey',
              borderWidth: '0 0 1px'
            }),
            color: isSelected ? 'white' : 'initial',
            backgroundColor: isSelected ? color : 'initial',
            opacity: isDisabled && !isSelected ? 0.4 : 1,
            ...(_.get(styles, 'option') ? styles.option(provided, state) : {})
          };
        },
        // We have to override the menu z-index because of the modal
        menu: base => ({
          ...base,
          zIndex: 9999
        })
      }} theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: color,
          primary75: 'initial',
          primary50: 'initial',
          primary25: 'initial'
        }
      })} id={this.id} name={name} isClearable={isClearable} isMulti={isMulti} isLoading={this.state.loading} menuPlacement={menuPlacement} placeholder={placeholder !== null ? placeholder : tr(`front.select.placeholder.${isMulti ? loadOptions ? 'type_then_multiple' : 'multiple' : loadOptions ? 'type_then_one' : 'one'}`)} loadingMessage={() => tr('front.select.loading')} options={options} noOptionsMessage={() => tr('front.select.no_options')} value={value} onChange={this.handleChange} inputValue={this.state.inputValue} onInputChange={this.handleInputChange} onKeyDown={this.handleKeyDown} />
                {(feedback && feedback !== true) === true && <div className='-feedback' data-test-id={`feedback-${name}`} style={{
        display: 'initial'
      }}>
                        {feedback}
                    </div>}
            </div>;
  };
}
DanimSelect.defaultProps = {
  allable: false,
  caption: '',
  creatable: false,
  customAttributes: {},
  debounceWait: 750,
  error: null,
  helper: null,
  isClearable: true,
  isMulti: false,
  label: '',
  loadOptions: null,
  menuPlacement: 'bottom',
  mountLoad: true,
  name: '',
  onChange: value => null,
  options: [],
  placeholder: null,
  styles: {},
  value: '',
  warning: null
};
DanimSelect.propTypes = {
  // optional
  allable: PropTypes.bool,
  // option added: 'All' to select all multi choices
  caption: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  creatable: PropTypes.bool,
  customAttributes: PropTypes.object,
  debounceWait: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.node]),
  helper: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.node]),
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // {
  //      request (may be a basic gql query string, or a promise factory),
  //      response (response transformer)
  // }
  loadOptions: PropTypes.object,
  menuPlacement: PropTypes.oneOf(['bottom', 'top']),
  mountLoad: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  placeholder: PropTypes.string,
  styles: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.bool]),
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.node])
};
export default DanimSelect;