import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import __Base from '@components/Base';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Play from './svg/Play';
class VideoFile extends __Base {
  constructor(props) {
    super(props);
    this.state = {
      mouseCounter: 0
    };
    this.handleMouseMoveThrottle = _.throttle(this.handleMouseMove, 200);
  }
  componentDidMount = () => document.addEventListener('mousemove', this.handleMouseMoveThrottle);
  componentDidUpdate = prevProps => {
    const {
      playing
    } = this.props;
    if (playing && !prevProps.playing) this.play();else if (!playing && prevProps.playing) this.pause();
  };
  componentWillUnmount = () => document.removeEventListener('mousemove', this.handleMouseMoveThrottle);
  handleMouseEnter = () => {
    const {
      onMouseOn
    } = this.props;
    let doCb = false;
    this.updateState(draft => {
      draft.mouseCounter++;
      if (draft.mouseCounter === 1) doCb = true;
    }, () => {
      if (doCb && onMouseOn) onMouseOn();
    });
  };
  handleMouseLeave = () => {
    const {
      onMouseOff
    } = this.props;
    let doCb = false;
    this.updateState(draft => {
      draft.mouseCounter--;
      if (draft.mouseCounter <= 0) {
        draft.mouseCounter = 0;
        doCb = true;
      }
    }, () => {
      if (doCb && onMouseOff) onMouseOff();
    });
  };
  handleMouseMove = () => {
    const {
      onMouseOn
    } = this.props;
    if (this.state.mouseCounter && onMouseOn) {
      onMouseOn();
    }
  };
  handlePlayClick = ev => {
    const {
      onMouseOn
    } = this.props;
    if (ev) ev.preventDefault();
    if (ev) ev.stopPropagation();
    if (onMouseOn) onMouseOn();
  };
  handleVolumeClick = ev => {
    const {
      onVolumeClick
    } = this.props;
    if (ev) ev.preventDefault();
    if (ev) ev.stopPropagation();
    onVolumeClick();
  };
  pause = () => {
    if (this.video && !this.video.paused) {
      this.video.pause();
      this.updateState({
        playing: false
      });
    }
  };
  play = () => {
    if (this.video && this.video.paused) {
      const playPromise = this.video.play();
      if (playPromise) {
        playPromise.then(_ => this.updateState({
          playing: true
        })).catch(_ => this.updateState({
          playing: false
        }));
      }
    }
  };
  render = () => {
    const {
      controls,
      customAttributes,
      loop,
      mute,
      playing,
      playsInline,
      poster,
      thrifty,
      src,
      videoOnlyWhenNeeded,
      volumable,
      isPictorial,
      onVolumeClick
    } = this.props;
    return <article ref={obj => this.root = obj} data-attr='root' {..._.get(customAttributes, 'root', {})} className={clsx('video', _.get(customAttributes, 'root.className'))} onMouseEnter={probe.desktop && !isPictorial ? this.handleMouseEnter : null} onMouseLeave={probe.desktop && !isPictorial ? this.handleMouseLeave : null}>
                {isPictorial === false ? <>
                        {!probe.desktop && !playing === true && <div className='-play' onClick={this.handlePlayClick} onTouchEnd={this.handlePlayClick}>
                                <Play />
                            </div>}
                        {(!playing && thrifty) === true && <img alt='' crossOrigin='anonymous' src={cors(absolute(poster))} />}
                        {(playing || !thrifty) === true && !!src && <video ref={obj => this.video = obj} data-attr='video' {..._.get(customAttributes, 'video', {})} className={clsx('-video', _.get(customAttributes, 'video.className'))} src={cors(videoOnlyWhenNeeded && !this.state.mouseCounter || !src ? null : absolute(src))} // for browser optimization, we avoid useless loads
        poster={cors(poster ? absolute(poster) : null)} muted={mute} loop={loop} controls={controls} playsInline={playsInline} // see https://stackoverflow.com/questions/5054560/can-i-avoid-the-native-fullscreen-video-player-with-html5-on-iphone-or-android
        />}
                        {(volumable && onVolumeClick && playing && probe.desktop && !controls) === true && <FontAwesomeIcon className={clsx('-volume', mute && '/mute')} icon={mute ? faVolumeMute : faVolumeUp} onClick={this.handleVolumeClick} width={16} />}
                    </> : <img alt='' src={cors(absolute(poster))} {..._.get(customAttributes, 'img', {})} />}
            </article>;
  };
}
VideoFile.defaultProps = {
  controls: false,
  customAttributes: {},
  loop: false,
  mute: false,
  onMouseOff: () => null,
  onMouseOn: () => null,
  onVolumeClick: () => null,
  playing: false,
  playsInline: true,
  thrifty: true,
  videoOnlyWhenNeeded: true,
  volumable: true
};
VideoFile.propTypes = {
  // optional
  poster: PropTypes.string,
  src: PropTypes.string,
  controls: PropTypes.bool,
  customAttributes: PropTypes.object,
  loop: PropTypes.bool,
  mute: PropTypes.bool,
  onMouseOff: PropTypes.func,
  onMouseOn: PropTypes.func,
  onVolumeClick: PropTypes.func,
  playing: PropTypes.bool,
  playsInline: PropTypes.bool,
  thrifty: PropTypes.bool,
  // whether we should use a <img> for static render, or only provide the poster attritbute in the <video>
  videoOnlyWhenNeeded: PropTypes.bool,
  volumable: PropTypes.bool
};
export default VideoFile;